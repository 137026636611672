import { pickBy } from 'lodash-es';
import {createQuery} from '~/service/Utils';

/**
 * @deprecated Ten service jest ze starego projektu, docelowo będzie to trzeba usunąć
 */
const WindowUtils = {

  stringify(obj: any) {
    return createQuery(obj);
  },

  searchToObject() {
    const params = window.location.search.substring(1);
    if (params) {
      const pairs = params.split('&');
      const obj: any = {};
      let pair;

      for (const i in pairs) {
        if (pairs[i] === '') { continue; }
        pair = pairs[i].split('=');
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }

      return obj;
    } else {
      return null;
    }
  },

  entryPage() {
    const searchObj = this.searchToObject();
    return searchObj ? searchObj.parentLocation : null;
  },

  getUrlParams() {
    const {query,} = useRoute();
    return query;
  },

  removeFalsyValue(x: any) {
    return pickBy(x, x => x !== null && x !== undefined && x !== 'null' && x !== 'undefined');
  },
};

export default WindowUtils;
