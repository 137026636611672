import type {LendiQuery} from "~/models/UTMs/UTMs";
import WindowUtils from "~/service/windowUtils";

const openNewTab = (url: string) => {
    window.open(url);
};

/**
 @WARNING!!!
 This method works only for NON parametrized paths. formularz-type-name wont work!! vue router has no option to push route to new tab
 */
export const openPathWithQueryInNewTab = (path: string, query: Partial<LendiQuery>) => {
    const {lendiUrl,} = useEnv();
    const queryString = query ? `?${WindowUtils.stringify(WindowUtils.removeFalsyValue(query))}` : '';
    openNewTab(`${lendiUrl}${path}${queryString}`);
};
